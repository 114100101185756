import React from "react";
import "./About.scss";
import Animation2 from "../../Components/Animation2/Animation2";

const About = () => {
  return (
    <div className="about">
      <div className="about-background-animation">
        <Animation2 />
      </div>
      <div className="about-container">
      <h1>About Inferbyte Technologies</h1> 
      
      <p> At Inferbyte Technologies, we are committed to transforming industries and empowering businesses through the limitless potential of artificial intelligence. Our mission is to create innovative, real-world solutions that not only enhance efficiency but also unlock new possibilities for growth and success. </p> 
      
      <p> We envision a future where cutting-edge AI tools drive progress, bridging the gap between complex challenges and practical, scalable solutions. By blending expertise in machine learning, data science, and intelligent automation, we aim to shape a smarter, more connected world. </p> 
      
      <p> Our team is the backbone of our success—a diverse group of professionals with expertise in technology, engineering, and business. United by a shared passion for innovation and problem-solving, we approach every project with a commitment to excellence and a drive to exceed expectations. </p> 
      
      <p> At Inferbyte Technologies, we believe in fostering strong partnerships with our clients, tailoring solutions to their unique needs, and helping them navigate the complexities of the digital age. With integrity, innovation, and excellence as our guiding principles, we strive to make a lasting impact through our work. </p> 
      
      <p> Join us as we shape the future of technology and explore the endless possibilities of AI. Together, we can build a smarter, more innovative tomorrow. </p>

        {/* <div className="about-section">
          <h2>Meet Our Team</h2>
          <p>Our team is composed of experienced professionals with diverse backgrounds in computer science, engineering, and business. Together, we bring a wealth of knowledge and expertise to every project, working collaboratively to deliver exceptional results.</p>
        </div> */}
      </div>
    </div>
  );
};

export default About;
