import React from "react";
import "./Services.scss";

const Services = () => {
  return (
    <div className="services">
      <div className="services-container">
        <h1>Our Services</h1>
        <p>
          Inferbyte Technologies offers a comprehensive suite of AI-based
          services designed to address the complex needs of modern businesses.
          Explore our offerings to see how we can help you achieve your goals
          with advanced technology.
        </p>

        <div className="service-list">
          <div className="service">
            <h2>Custom Software Development</h2>
            <p>
              We develop websites, apps, and custom software solutions tailored to meet your specific business needs, driving innovation and improving operational efficiency.
            </p>
          </div>

          <div className="service">
            <h2>Custom LLMs</h2>
            <p>
              We specialize in training custom large language models (LLMs) for a variety of applications, ensuring optimal accuracy and performance in tasks like image and speech recognition.
            </p>
          </div>

          <div className="service">
            <h2>Deep Learning Solutions</h2>
            <p>
              Our expertise in deep learning allows us to create powerful models that can detect patterns and make predictions, unlocking actionable insights from complex data sets.
            </p>
          </div>

          <div className="service">
            <h2>Predictive Analytics</h2>
            <p>
              We help you forecast future trends with predictive analytics, enabling data-driven decisions to optimize business outcomes.
            </p>
          </div>

          <div className="service">
            <h2>AI Strategy Consulting</h2>
            <p>
              Our AI strategy consulting helps businesses navigate AI adoption, aligning initiatives with business goals to ensure maximum ROI and effective implementation.
            </p>
          </div>
        </div>

        <div className="contact-button">
          <a href="/contact" className="btn">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Services;
