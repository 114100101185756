import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/" className="logo-link">
          <img src="../../logo.png" alt="Company Logo" className="logo-image" />
          <span className="company-name">INFERBYTE TECHNOLOGIES</span>
        </Link>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        <i className={isMenuOpen ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <ul className={isMenuOpen ? "nav-links active" : "nav-links"}>
        <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
        {/* <li><Link to="/about" onClick={toggleMenu}>About</Link></li> */}
        <li><Link to="/services" onClick={toggleMenu}>Services</Link></li>
        {/* <li><Link to="/insights" onClick={toggleMenu}>Insights</Link></li> */}
        {/* <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
