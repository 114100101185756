import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Animation1 from "../../Components/Animation1/Animation1";
import "./Home.scss";

const Home = () => {
  useEffect(() => {
    // Intersection Observer to add 'visible' class when sections are in view
    const sections = document.querySelectorAll(".section");

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is in view
    );

    sections.forEach((section) => {
      observer.observe(section);
    });
  }, []);
  return (
    <div className="home">
      <div className="banner">
        <div className="banner-gradient-horizontal"></div>
        <div className="banner-gradient-vertical"></div>
        <div className="banner-animation-1">
          <Animation1 />
        </div>
        <div className="banner-text">
          <h1>Innovating the Future with the Power of AI</h1>
          <p>Driving progress and shaping a smarter world through technology</p>
          <div className="banner-buttons">
            <Link to="/services" className="primary-button">
              Explore Our Services
            </Link>
            <Link to="/contact" className="secondary-button">
              Contact Us
            </Link>
          </div>
        </div>
      </div>

      <div className="home-sections">
        <div className="section-1 section">
          <h2>About INFERBYTE</h2>
          <p>
            At INFERBYTE TECHNOLOGIES, we specialize in AI-powered solutions
            that help businesses optimize processes, improve decision-making,
            and innovate for the future. Our team is dedicated to pushing the
            boundaries of technology to deliver solutions that make an impact. With a focus on artificial intelligence, machine learning, and deep
            learning, we provide tailored services that empower organizations to
            leverage cutting-edge technologies. Whether you're looking to
            enhance your software, create intelligent systems, or dive into
            predictive analytics, we have the expertise to help.
          </p>
          <a href="/about" className="btn">
            Learn More About Us
          </a>
        </div>

        <div className="section-2 section">
          <h2>Our Services</h2>
          <p>
            Explore our comprehensive range of AI-based services designed to
            meet your business needs. Whether you're looking for custom software
            development, AI strategy consulting, or predictive analytics, we
            provide the tools to help your business thrive in an AI-driven
            world. Our services are built to scale with your business. From small
            startups to large enterprises, we design flexible solutions that
            grow with your evolving needs. Let us be your trusted partner in
            transforming your business with AI.
          </p>
          <a href="/services" className="btn">
            View All Services
          </a>
        </div>

        <div className="section-3 section">
          <h2>Ready to Innovate with AI?</h2>
          <p>
            AI is no longer just a futuristic concept – it’s shaping the
            present. At INFERBYTE, we’re here to help you unlock the power of AI
            and revolutionize your business. Our team will work closely with you
            to understand your goals and develop intelligent solutions that
            drive measurable results. Whether you’re starting your AI journey or scaling up your existing
            AI-driven projects, we’re here to provide the expertise and support
            you need to succeed. Together, we can create solutions that exceed
            your expectations.
          </p>
          <a href="/contact" className="btn">
            Get in Touch
          </a>
        </div>

        {/* <div className="section-4 section"></div> */}
      </div>
    </div>
  );
};

export default Home;
